<template>
    <div>
        <!-- NavBar -->
        <n-navbar />
        <!-- Main -->
        <main class="px-3 pb-3">
            <!-- Logo -->
            <div class="w-100 text-center">
				<!-- <img src="@/assets/logo.png" width="248" /> -->
			</div>
            <!-- Form -->
            <form class="bg-lightwhite border-radius-8 p-3" style="margin-top: 50%;" @submit.stop.prevent="submit">
                <div class="font-17 font-weight-500 text-dark-warm-grey px-2">會員登入</div>
                <div class="form mt-3 mb-2">
                    <div class="mb-2">
                        <!-- PhoneInput -->
                        <div class="form-group">
                            <label class="bags bg-transparent">電話號碼</label>
                            <input v-model="phone" class="border-radius-8" type="tel" placeholder="請輸入電話" required />
                            <button id="send-btn" class="primary-btn verifiy-btn flex-center" style="font-size: 12px;" :disabled="!validatePhone || isSending" @click.prevent="sendSMS">取得驗證碼</button>
                        </div>
                        <!-- CodeInput -->
                        <div class="form-group mt-2">
                            <label class="bags bg-transparent">驗證碼</label>
                            <input v-model="code" class="border-radius-8" type="number" placeholder="請輸入驗證碼" autocomplete="one-time-code" required />
                        </div>
                    </div>
                </div>
                <!-- SubmitButton -->
                <button class="btn primary-btn flex-center mt-3" type="submit" :disabled="!validateLogin">
                    <div v-if="isLoading" class="lds-ring my-20 mx-auto">
                        <div></div>
                    </div>
                    <span v-else class="font-19">登入</span>
                </button>
            </form>
        </main>
    </div>
</template>

<script>
import firebase from 'firebase/app'
import { mapState } from 'vuex'
import { db } from '@/firebase'

export default {
    data() {
        return {
            isSending: false,
            isLoading: false,
            confirmationResult: null,
            phone: '',
            code: '',
        }
    },

    mounted() {
        firebase.auth().languageCode = 'en'
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('send-btn', { 'size': 'invisible' })
    },

    async created() {
        if (localStorage.getItem(`${this.shop.merchantId}-customerId`)) return this.$router.push(`/${this.shop.id}`)
    },

    computed: {
        ...mapState({
            shop: state => state.shop,
        }),

        validatePhone() {
            return this.phone.length == 8
        },

        validateLogin() {
            return this.phone.length == 8 && this.code.length == 6 && !this.isLoading
        },
    },

    methods: {
        async sendSMS() {
            this.isSending = true
            this.confirmationResult = await firebase.auth().signInWithPhoneNumber('+852' + this.phone, window.recaptchaVerifier)
        },

        async submit() {
            this.isLoading = true
            if (!this.confirmationResult) return this.isLoading = false

            const result = await this.confirmationResult.confirm(this.code)
            const customer = await db.collection('customers').doc(this.shop.merchantId + result.user.uid).get()

            if (!customer.exists) return this.$router.push({ name: 'Register', params: { uid: result.user.uid, phone: result.user.phoneNumber.replace('+852','') } })
            
            localStorage.setItem(`${this.shop.merchantId}-customerId`, this.shop.merchantId + result.user.uid)
            this.$store.dispatch('bindDocument', { col: 'customers', id: localStorage.getItem(`${this.shop.merchantId}-customerId`), target: 'customer' })
            this.$router.push(`/${this.shop.id}/home`)
        }
    }
}
</script>